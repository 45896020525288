import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import Card from 'src/UIComponents/Card/Card'

import { normalText, bigText, darkGray, darkerGray } from 'src/Styles/settings/Constants'

const MobileMessageForUser = ({ icon, title, message }) => (
  <Wrapper>
    <CardWrapper>
      <Icon src={icon} />
      <Title>{title}</Title>
      <Message>{message}</Message>
    </CardWrapper>
  </Wrapper>
)

MobileMessageForUser.defaultProps = {
  icon: '/assets/icons/others/computer.svg',
  title: 'Estamos no desktop',
  message: 'Fique tranquilo, em breve traremos\numa incrível experiência. Enquanto\nisso, você pode acessar o Dash pelo\ncomputador.'
}

MobileMessageForUser.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
`

const CardWrapper = styled(Card)`
  padding: 6.7rem 2.5rem;
`

const Icon = styled(ReactSVG)`
  svg {
    fill: ${darkerGray};
    width: 6rem;
    height: 6rem;
  }
`

const Title = styled.span`
  font-weight: bold;
  font-size: ${bigText};
  line-height: 19px;
  text-align: center;
  color: ${darkGray};
  margin-top: 1.5rem;
`

const Message = styled.p`
  font-size: ${normalText};
  line-height: 20px;
  text-align: center;
  color: ${darkerGray};
  margin-top: 1rem;
  white-space: pre-wrap;
`

export default MobileMessageForUser
