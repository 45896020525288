import React from 'react'
import ReactSVG from 'react-svg'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Label from '../../UIComponents/Label/Label'
import { biggestText, darkerGray, white } from '../../Styles/settings/Constants'

const Modal = ({ children, handleOnClose, styleProps, isSidebar }) => {
  return (
    <Container isSidebar={isSidebar}>
      <ModalContainer styleProps={styleProps}>
        {handleOnClose && <CloseIcon onClick={handleOnClose} src='/assets/icons/actions/close.svg' data-test='modal-close-btn' />}
        { children }
      </ModalContainer>
    </Container>
  )
}

const ModalHeader = ({ children }) => {
  return (
    <HeaderContainer>
      <Label fontSize={biggestText} bold>{ children }</Label>
    </HeaderContainer>
  )
}

const ModalBody = ({ children }) => {
  return (
    <BodyContainer>{ children }</BodyContainer>
  )
}

const ModalFooter = ({ children }) => {
  return (
    <FooterContainer>{ children }</FooterContainer>
  )
}

Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter

Modal.propTypes = {
  children: PropTypes.node,
  handleOnClose: PropTypes.func,
  styleProps: PropTypes.object,
  isSidebar: PropTypes.bool
}

ModalHeader.propTypes = {
  children: PropTypes.node
}

ModalBody.propTypes = {
  children: PropTypes.node
}

ModalFooter.propTypes = {
  children: PropTypes.node
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: ${props => (props?.isSidebar) ? 'calc(-100vw + 460px)' : '0'};
  background-color: rgba(78, 78, 78, 0.5);
  z-index: 9999;
`

const ModalContainer = styled.div`
  max-width: ${props => (props.styleProps && props.styleProps.maxWidth) ? props.styleProps.maxWidth : '990px'};
  min-width: ${props => (props.styleProps && props.styleProps.minWidth) ? props.styleProps.minWidth : '300px'};
  height: ${props => (props.styleProps && props.styleProps.height) ? props.styleProps.height : '88vh'};
  max-height: 80vh;
  background-color: ${white};
  border-radius: 5px;
  box-shadow: 0px 3px 10px ${darkerGray};
  padding: ${props => props.styleProps?.padding || '70px 80px'}; 
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const HeaderContainer = styled.div`
  border-bottom: 1px solid #B8B8B8;
  padding-bottom: 30px;
`

const BodyContainer = styled.div`
  margin: 30px 0;
  max-height: calc(100% - 120px);
  overflow: scroll;
  scroll-behavior: smooth;
`

const FooterContainer = styled.div`
  text-align: center;
`

const CloseIcon = styled(ReactSVG)`
  z-index: 1;
  color: ${darkerGray};
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;

  svg {
    fill: ${darkerGray};
  }
`

export { Modal }
