import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { gray, normalText, textBold } from 'src/Styles/settings/Constants'

const MessageUpLine = ({ children }) => (
  <Wrapper>
    <Line />
    {children}
    <Line />
  </Wrapper>
)

MessageUpLine.propTypes = {
  children: PropTypes.any
}

const Wrapper = styled.div`
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 4.6rem;
  color: ${gray};
  font-size: ${normalText};
  font-weight: ${textBold};
`

const Line = styled.div`
  height: 1px;
  width: 94px;
  margin: 10px 8px;
  background-color: #E5E5E5;
`

export default MessageUpLine
