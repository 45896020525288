import { css } from 'styled-components'

const widths = {
  phoneSmall: '375px',
  phone: '400px',
  phoneWide: '480px',
  phablet: '560px',
  tabletSmall: '640px',
  tablet: '768px',
  tabletWide: '1024px',
  desktop: '1248px',
  desktopWide: '1440px'
}

export const mq = Object.keys(widths).reduce((acc, width) => {
  acc[width] = (...args) => css`
      @media only screen and (min-width: ${widths[width]}) {
         ${css(...args)};
      }
   `

  return acc
}, {})
